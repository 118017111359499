.footer-v1 {

	.icon-link {
		padding-top: 10px;

		.fa {
			color: var(--color-white);
			margin-right: 5px;
		}
	}

	.avg-feedback {
		display: inline-block;
		position: relative;
		padding-left: 45px;

		&:hover {
			text-decoration: none;
		}

		.ty-content-bl {

			.ty__content {
				text-align: left;
				line-height: 1;

				span {
					font-size: 15px;
				}
			}

			.stars {
				margin-right: 5px;
				display: flex;
				align-items: center;

				i {}
			}

			.fa {
				color: #7cb342;
				font-size: 10px;
				margin-right: 2px;

				&.fa-white {
					color: #fff;
				}
			}
		}

		.ty__scoremeta {
			display: flex;
			margin-top: 2px;

			span {
				opacity: .5;
				font-size: 12px;
			}
		}

		.ty__score {
			position: absolute;
			background: #7cb342;
			width: 30px;
			height: 30px;
			font-size: 16px;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 0.5rem;
			left: 0;
			top: 4px;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 0;
				border: 0.3rem solid transparent;
				border-top-color: #7cb342;
				border-bottom: 0;
				border-left: 0;
				margin-bottom: -0.3rem;
			}
		}
	}

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}