.static-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.activity {
			background-image: url(RESOURCE/img/banner-activity.jpg);
		}

		&.region {
			background-image: url(RESOURCE/img/banner-region.jpg);
		}
	}

	.owl-carousel {
		position: relative;

		.owl-nav button {
			position: absolute;
			top: 50%;
			background-color: transparent;
			color: #fff;
			margin: 0;
			transition: all 0.3s ease-in-out;
		}

		.owl-nav button.owl-prev {
			left: 20px;
		}

		.owl-nav button.owl-next {
			right: 20px;
		}

		.owl-dots {
			text-align: center;
			padding-top: 15px;
			position: absolute;
			bottom: 20px;
			width: 100%;
		}

		.owl-dots button.owl-dot {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			display: inline-block;
			background: #ccc;
			margin: 0 3px;
		}

		.owl-dots button.owl-dot.active {
			background-color: var(--color-primary);
		}

		.owl-dots button.owl-dot:focus {
			outline: none;
		}

		.owl-nav button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

		}

		span {
			font-size: 70px;
			position: relative;
			top: -5px;
		}

		.owl-nav button:focus {
			outline: none;
		}
	}
}