.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;
			/*display: flex;*/
			align-items: center;

			.fa {
				width: 30px;

				&.fa-tr {
					background-image: url(RESOURCE/img/terrace_icon.png);
					height: 15px;
					width: 15px;
					background-size: cover;
					background-repeat: no-repeat;
					margin-right: 14px;
				}
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.ul-nopints {
		li {
			i.icon-garage {
				width: 16px;
				height: 13px;
				display: inline-block;
				opacity: .75;
				background-size: contain;
				margin-right: 13px;
			}
		}
	}


	.stars {
		color: var(--color-stars);
	}

}