.coupon-v1 {

	padding: 20px 20px;
	border: 1px solid var(--color-grey-normal);
	background-color: var(--color-white);

	.coupon-field {
		margin-top: 20px;

		.input-group {
			.form-control {
				border-radius: 0.25rem !important;
				margin-right: 6px;
			}

		}
	}
}